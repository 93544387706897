import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const CustomToolTip = withStyles(theme => ({
  tooltip: {
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

class Asterisk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      text: "",
    };
  }


  render() {
    return (
      <CustomToolTip title={this.props.text} arrow>
        <div style={{ display: "inline-block" }}><p style={{ color: "red", margin: "auto" }}>*</p></div>
      </CustomToolTip>
    );
  }
}

export default Asterisk;
